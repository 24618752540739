<mat-progress-spinner *ngIf="loading"
                      class="mobile-data-table__spinner"
                      mode="indeterminate"
                      color="accent">
</mat-progress-spinner>
<div class="flex-container"
     fxLayout="column"
     fxLayoutAlign="center center"
     *ngIf="!isDataAvailable() && !loading">
  <data-table-error [errorText]="noResultsMessage"
                    [actionableErrorText]="actionableErrorText"
                    (textClick)="errorTextClick.emit()">
  </data-table-error>
</div>
<div class="mobile-data-table__container" *ngIf="isDataAvailable() && !loading">
  <mat-nav-list class="mobile-data-table-mat-list">
    <mat-list-item *ngFor="let row of rows; let i = index"
                   [ngClass]="{'mobile-data-table__blue-row-background' : (i % 2  !== 0),
                                'mobile-data-table__white-row-background' : (i % 2  === 0)}"
                   (click)="rowClicked(row)"
                   fxLayout="row"
                   fxLayoutAlign="space-between center"
                   class="mobile-data-table__list-item">
      <div *ngIf="mobileDeleteEnabled" fxFlex="15">
        <img (click)="$event.stopPropagation(); deleteClicked(row)"
             src="https://www.cfacdn.com/img/Spotlight/Icons/Delete_RedIcon.png"/>
      </div>
      <div *ngIf="mobileEditEnabled" fxFlex="15">
        <img (click)="$event.stopPropagation(); editClicked(row)"
             src="https://www.cfacdn.com/img/Spotlight/Icons/Edit_RedIcon.png"/>
      </div>
      <div fxFlex="78">
        <div mat-line *ngFor="let col of mobileColumns">
          <data-table-cell [row]="row" [col]="col" [isMobile]="true"></data-table-cell>
        </div>
      </div>
      <mat-icon class="mobile-data-table__chevron" fxFlex="20">keyboard_arrow_right</mat-icon>
      <mat-divider class="mobile-data-table__divider"></mat-divider>
    </mat-list-item>
  </mat-nav-list>
  <data-table-footer
    *ngIf="showFooter"
    (onPageSizeChange)="pageSizeChange($event)"
    (onPageChange)="changeCurrentPage($event)"
    [totalElements]="totalElements"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [currentPage]="currentPage"
    [numberOfPages]="numberOfPages"
    [isMobile]="true">
  </data-table-footer>
</div>
